export const environment = {
    production: false,
    servicesAuthUrl: "/fascicolo-autenticazione",
    servicesDataUrl: "/fascicolo-dati",
    servicesSystemUrl: "/fascicolo-sistema",
    servicesUsersUrl: "/fascicolo-utenti",
    accessmanager: {
        clientid: "test.fascicoloimmobilipubblici",
        scope: "read",
        basepath: "https://accessmanager.demo.sardegnait.it/access-manager",
        authorizeUri: "/oauth/authorize",
        userinfoUri: "/SSOServiceOAuth2",
        logoutUri: "/revoke-token",
        bypass: false
    },
};
